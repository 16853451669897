module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://38d9e31224b04e4aba61274d50ca3cca@sentry.io/1467750"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
